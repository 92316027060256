import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P, UL,
	BackLink,
} from '../../shared/components/Layout';

export const PrivacyPage = () => {
	useScrollToTop();
	usePageTitle('Privacy Policy');

	return (
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Privacy Policy</H1>

					<P><b>Important:</b> please read this Privacy Policy together with our Terms and conditions as they set out the terms and conditions for using Swimpion website (“website”) and Swimpion application (“application”, “app”). If you use our website and/or app, you accept this Privacy Policy and these Terms and Conditions and agree to the terms they set out. If you are under the age of 13, you confirm that you have parental consent to open an account with us, provide us with your email address and use our website and/or app. You must not use Swimpion website and/or application if you do not agree to our Privacy Policy and Terms and Conditions.</P>
					<P>Gama Software Ltd, a Limited Liability Company based in Edinburgh, UK, owns and operates the internet platform swimpion.com and Swimpion application. Swimpion is an application for keeping track of best swim times, meet results and swimming workouts. The app is suitable for all age groups.</P>
					<P>We fully respect your right to privacy when using Swimpion website and application. Here you will find details of our privacy practices and what we do to maintain your right to privacy.</P>			

					<H2>What information we collect about you?</H2>
					<P>Personal data refers to any information which relates to you as an individual and from which you can be identified, either directly or indirectly. You have the option to use our app as a guest without providing personal information, however, when you sign up to use Swimpion app, we need the following information:</P>
					<UL>
						<li>Your name (can be username);</li>
						<li>Your email address;</li>
						<li>Password;</li>
						<li>Gender (optional, for FIFA points calculation only);</li>
						<li>Weight (optional, for burned calories calculation only).</li>
					</UL>
					<P>We need this information to open your account where you can save your swimming entries and can access them at a later time or on multiple devices.</P>
					<P>When you use Swimpion application and/or website, we collect information from and about the computers, phones, tablets and other web-connected devices you use to access Swimpion app. Information we obtain from these devices includes:</P>
					<UL>
						<li>Device attributes: information such as the operating system, hardware and software versions, browser type.</li>
						<li>Network and connections: information such as the name of your mobile operator or ISP, language, time zone, IP address, connection speed.</li>
					</UL>
					<P>We use Google Analytics to monitor app and website usage and evaluate the effectiveness of our app and website. This information is not personally identifiable.  We chose third party providers that we use very carefully who will provide the same protection of your data as stated in this Privacy Policy.</P>
					<P>We make no attempt to identify individual users of our app and website, unless we suspect that unauthorised access to our systems is being attempted. We reserve the right to attempt to identify and track any individual who is reasonably suspected of trying to gain unauthorised access to our computer systems. As a condition of using our app and website, all users give permission for Swimpion to use its access logs to attempt to track users who are reasonably suspected of gaining, or attempting to gain, unauthorised access.</P>

					<H2>Why we need and how will we use your personal information?</H2>
					<P>We need your email address, password and name/username to enable you to use Swimpion app and to provide you with our services which include some or all of the following:</P>
					<UL>
						<li>To save your swimming entries for you to be able to access them at a later time and on multiple devices;</li>
						<li>Manage enquires, requests and complaints which you submit to us;</li>
						<li>Provide opportunities to give us feedback on our services, to help us know what you need and improve our services appropriately;</li>
						<li>Provide you with notifications you have opted in to receive;</li>
						<li>Allow you to comment on our social media pages;</li>
						<li>Conduct data and statistical analysis to monitor performance of our services and make improvements;</li>
						<li>We might use some of your data for the purpose of market research, and for the purpose of advertising on our app;</li>
						<li>We may disclose your information to competent law enforcement bodies, regulatory, government agencies, court or other third parties where we have the legal obligation to do so.</li>
						<li>We might share your data with actual or potential buyers (and their agents and advisers) in connection with any actual or proposed purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must use your data only for the purposes disclosed in this Privacy Policy.</li>
					</UL>
					
					<H2>How do we protect and look after your information?</H2>
					<P>Your personal information is stored in our electronic filing system and our servers based in Europe, and is accessed by our staff for the purposes set out above.</P>
					<P>The principles of the General Data Protection Regulation (GDPR) require us to make sure your data is accurate, kept up-to-date and that we keep it for no longer than is necessary.</P>
					<P>We chose third party providers (Google Analytics) very carefully as they have very strict policies regarding Data Protection and will provide the same protection of your data as stated in this Privacy Policy.</P>

					<H2>Your options and closing your account</H2>
					<P>You can revoke your consent and delete your account and user data at any time. Please note, that if you delete your account, you will not be able to access it on any other device. If you change your mind and decide to re-join Swimpion again, unfortunately, we will not be able to retrieve your old data.</P>
					<P>Under the General Data Protection Regulation (GDPR), you have the right to access, rectify, port and erase your data:</P>
					<UL>
						<li>You can request more details about the information we collect about you and how and why we use it.</li>
						<li>You can have your information corrected or deleted. You can update your information on your profile or delete your data by closing your account.</li>
						<li>You can object to us processing your information. You can ask us to stop using your information, including when we use your information to send you marketing emails or push notifications. If you opt out of receiving marketing messages from us, we may still send you updates related to your account.</li>
					</UL>
					<P>If you have any questions about the above, please do not hesitate to <a href="mailto:info@swimpion.com" title="Write us an email">contact us</a>.</P>

					<H2>Cookies Policy</H2>
					<P>Cookies are small text files containing a string of characters that can be placed on your computer or mobile device that uniquely identifies your browser or device. Cookies allow a website or services to know if your computer or device has visited it before.</P>
					<P>The main reason we use cookies is to help us understand how the website or service is being used, how we can help you navigate between pages efficiently, cookies help us to remember your preferences, and generally improve your experience in using our services. Cookies can also help ensure marketing you see online is more relevant to you and your interests.</P>
					<P>The length of time a cookie will stay on your computer or mobile device depends on whether it is a "persistent" or "session" cookie. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your computer or mobile device until they expire or are deleted.</P>
					<P>You can set your browser not to accept cookies. Follow the instructions provided by your website or mobile browser (usually located within the "Help", "Tools" or "Edit" facility) to modify your cookie settings. Please note that if you set your browser to disable cookies, some features of our website or service may not work properly as a result.</P>
					
					<H2>Other websites</H2>
					<P>Our app and website may include links to other applications or websites. Our Privacy Policy applies to Swimpion website and Swimpion application only so when you follow the links to other applications or websites you may wish to read their privacy policies.</P>
					
					<H2>International data transfers</H2>
					<P>Our website and app servers are currently located in the EU. By using our service, you consent to the transfer of your data outside of the EU.</P>
					
					<H2>Updates to this Privacy Policy</H2>
					<P>We keep our Privacy Policy under regular review. Any changes we may make to our Privacy Policy in the future will be posted on this page. Use of information we collect from you now is subject to the Privacy Policy in effect at the time such information is used. You are bound by any changes to the Privacy Policy when you use our website and/or application after such changes have been first posted.</P>
					<P>This privacy policy was last updated on 1<sup>st</sup> of October, 2023.</P>
					
					<H2>Question about our Privacy Policy?</H2>
					<P>Please <a href="mailto:info@swimpion.com" title="Write us an email">contact us</a> if you have any questions about our Privacy Policy.</P>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}