import { usePageTitle } from '../../shared/hooks';
import { AppLogo } from '../../shared/components/Layout/components/AppLogo';
import { Layout } from '../../shared/components/Layout';
import { AppSlogan } from './components/AppSlogan';
import { AppImages } from './components/AppImages';
import { FeatureList } from './components/FeatureList';
import { AppDescription } from './components/AppDescription';
import { DownloadButtons } from '../../shared/components/DownloadButtons';
import { Intro, Content } from './styles';

export const HomePage = () => {
	usePageTitle('');
	
	return (
		<Layout>
			<Intro>
				<Content>
					<AppLogo />
					<AppSlogan />
					<DownloadButtons small={true} showTitle={false} />
				</Content>

				<AppImages />
				<AppDescription />
			</Intro>

			<FeatureList />
		</Layout>
	)
}