import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-4.webp';
import screen2 from '../../assets/screens/all/opt-2.png';
import screen3 from '../../assets/screens/all/opt-11.png';
import screen4 from '../../assets/screens/all/opt-12.png';


export const WorkoutsPage = () => {
	useScrollToTop();
	usePageTitle('Workouts - Swim training analysis');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Workouts</H1>
					<HeadingImg src={headerImg} alt="Bondi beach swimming" />				

					<H2>Swim Training Analysis</H2>
					<P>Enter the duration and distance that you swam during your swim training and we will calculate your swim pace and calories burned during that swim session.</P>
					
					<ImgRow>
						<Img src={screen3} alt="App screenshot of Add Workout page" />
						<Img src={screen4} alt="App screenshot of workout sessions with calculated calories" />
					</ImgRow>

					<P>Monthly workout summaries show your average distance per session, total calories burned, total swim hours and total swim distance per month and compare those with previous month data. Visual swim workout graph shows your workout patterns.</P>
			
					<ImgRow>
						<Img src={screen2} alt="App screenshot of Monthly Workout Details page"/>
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}