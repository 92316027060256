import { Col, ColLeft, ColRight, Container, Content, Img, Inner, Small, Title } from './styles';
import swimmerSrc from '../../../../assets/random/opt-swimmer.jpg';
import parentsSrc from '../../../../assets/random/opt-parents.jpg';

export const AppDescription = () => (
	<>
		<Container>
			<Inner>
				<ColRight>
					<Title>
						<Small>Made for</Small><br />Swimmers.
					</Title>

					<Content>
						Swimmers who want to improve swim times, set time goals and compare their swim times with other same age-group swimmers; keep swim meet results organised and log swim workouts after trainings.
						<br /><br />Swimpion app was designed for competitive swimmers but is useful for anyone who swims for fitness and wants to keep track of their progress and swim times.
					</Content>
				</ColRight>

				<Col>
					<Img src={swimmerSrc} alt="Swimmer jums into pool" />
				</Col>
			</Inner>
		</Container>


		<Container isNext={true}>
			<Inner isNext={true}>
				<Col>
					<Img src={parentsSrc} alt="Parents sitting in the stands" />
				</Col>
				
				<ColLeft>
					<Title>
						<Small>Made for</Small><br />Parents.
					</Title>

					<Content>
						Parents who want to track swimming progress of their children and keep their swim meet results in one place. Parents, coaches, and swimmers alike can set and track time goals, compare results, and enjoy a range of features designed to enhance swimming journey.
					</Content>
				</ColLeft>
			</Inner>
		</Container>
	</>
)