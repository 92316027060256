import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
`;

export const H1 = styled.h1`
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 74px;
    text-align: center;
    text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);
    color: #1d1d1f;
    margin: 80px 0 70px 0;
    letter-spacing: -2px;
    

    @media only screen and (max-width: 760px) {
        font-size: 44px;
        line-height: 54px;
    }

    @media only screen and (max-width: 480px) {
        font-size: 34px;
        line-height: 44px;
        margin: 60px 0 70px 0;
    } 

    @media only screen and (max-width: 360px) {
        font-size: 30px;
        line-height: 40px;
    }
`;