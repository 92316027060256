import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ModalNavigation } from './components/ModalNavigation';
import { DownloadButtons } from '../DownloadButtons';
import { Wrapper, Content, FooterContainer, DownloadContainer } from './styles';
import { LayoutProps } from './types';
import { useState } from 'react';
import { useDependencies } from '../../hooks';


export const Layout = (props: LayoutProps) => {
    const { children } = props;
	const [isModalNavOpen, setIsModalNavOpen] = useState(false);
	

	const toggleSecondaryNav = () => {
		const isModalOpen = !isModalNavOpen;
		setIsModalNavOpen(isModalOpen);

		if (isModalOpen === true) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
	}

	// const closeSecondaryNav = () => {
	
	// }


	return (
		<Wrapper>
			<Header isHamburgerActive={isModalNavOpen} onHamburgerClick={toggleSecondaryNav} />
			
			{children}

			<DownloadContainer id="download">
				<DownloadButtons />
			</DownloadContainer>

			<FooterContainer>
				<Content>
					<Footer />
				</Content>
			</FooterContainer>

			{isModalNavOpen &&
				<ModalNavigation onCloseModal={toggleSecondaryNav} />
			}
		</Wrapper>
	)
}