import { NavLink } from 'react-router-dom';

import { Wrapper, Inner, LogoText, RightSide } from './styles';
import logoText from '../../../../../assets/logo/swimpion-text.svg';
import { Hamburger } from '../Hamburger';
import { HeaderProps } from './types';


export const Header = (props: HeaderProps) => {
    const { isHamburgerActive, onHamburgerClick } = props;

    return (
        <Wrapper>
            <Inner>
                <NavLink to="/" title="Navigate to Swimpion home page">
                    <LogoText src={logoText} alt="Logo of Swimpion" width="110" height="22.55" />
                </NavLink>
                
                <RightSide>
                    <Hamburger active={isHamburgerActive} onClick={onHamburgerClick} />
                </RightSide>
            </Inner>
        </Wrapper>
    )
}