import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-11.webp';
import screen2 from '../../assets/screens/all/opt-8.png';


export const MultipleAccountsPage = () => {
	useScrollToTop();
	usePageTitle('Multiple Accounts - Control multiple accounts from one app');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Multiple Accounts</H1>
					<HeadingImg src={headerImg} alt="Bondi beach swimming" />				

					<H2>Control multiple accounts from one app</H2>
					<P>Useful if a parent is also a swimmer and wants to track their own swim times as well as their children’s swimming times.</P>
					
					<ImgRow>
						<Img src={screen2} alt="App screenshot of how to add multiple accounts" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}