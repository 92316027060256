import styled from 'styled-components'
import { HashLink as NavLink } from 'react-router-hash-link';
import { ButtonStyleProps } from './types';

export const Btn = styled(NavLink)<ButtonStyleProps>`
   color: #fff;
   background-color: #000;
   padding: 10px 20px;
   border-radius: 30px;
   font-weight: 300;
   text-decoration: none;
   font-family: "Karla";
   text-align: center;
   width: fit-content;

   font-size: 16px;
   ${props => props.size === 's' && 'font-size: 14px;'}
   ${props => props.size === 'm' && 'font-size: 16px;'}
   ${props => props.size === 'l' && 'font-size: 18px;'}

   :hover {
      background-color: rgb(112, 112, 114);
   }
`;