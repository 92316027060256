import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	BackLink,
} from '../../shared/components/Layout';

export const TermsPage = () => {
	useScrollToTop();
	usePageTitle('Terms And Conditions');

	return (
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Terms & Conditions</H1>
					<P>Gama Software Ltd owns and operates the internet platform Swimpion  (“website”) and Swimpion application (“application”, “app”). Swimpion is an application for swimmers to analyse their swimming workouts, count calories, keep track of swimming times, set personal time goals and workout challenges.</P>
					<P>This page, along with our Privacy Policy, sets out the terms and conditions for using Swimpion application and website (‘our app and website‘).</P>
					<P>You should read these Terms and Conditions and our Privacy Policy carefully before using our app and/or website as they apply as soon as you start using them. If you use our app and website, you accept these Terms and Conditions and our Privacy Policy and agree to the terms they set out. If you are under the age of 13, you confirm that you have parental consent to open an account with us, provide us with your email address, username, your gender and weight (for FIFA points calculation only), and use our website and/or app. You must not use our app and/or website, if you do not agree to these Terms and Conditions and our Privacy Policy.</P>
					<H2>Accessing our application and website</H2>
					<P>Swimpion app and website are maintained for your personal use and we allow access to them on a temporary basis and reserve the right to withdraw or amend the service we provide on our app and website without any notice. We will not be liable if for any reason our app and/or website is unavailable at any time or for any period.</P>
					<P>You are also responsible for making sure that anyone who uses Swimpion app or website through your internet connection or via your mobile or other devices, is aware of these terms and that they comply with them.</P>
					<H2>Disclaimer</H2>
					<P>We strive to provide information and service of the highest quality. However, the material displayed on our app and website is provided without any guarantees, conditions or warranties as to its accuracy.</P>
					<P>While we try to make sure that the information published on our app and website is up-to-date and accurate, and that the sources of information and support listed are useful, please note that the information contained on our app and website is provided as general information only and does not constitute advice to you. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any user of our app and website or by anyone who may be informed of any of its contents.</P>
					<P>We take no responsibility for the contents of linked websites, or the availability of any pages we link to. We take no responsibility for the services provided by organisations we list and/or link to. We do not accept liability for any loss, damage or inconvenience arising as a consequence of the use of our app or our website.</P>
					<P>We also do not guarantee that our app and website functions will be uninterrupted or error free; that defects will be corrected; that our app and website or the server that makes them available are free of viruses. We will not be left liable for any issues, or loses, relating to the operation of our app, website or our servers.</P>
					<H2>Intellectual property rights</H2>
					<P>All copyright and other intellectual property rights in all text, images, sound, software, logos and other materials (‘the content”) on Swimpion app and website are owned by Gama Software Ltd, unless otherwise stated. The contents of the app and website may not be copied, reproduced, distributed, republished, downloaded, posted or transmitted in any form or by any means without prior written consent from Gama Software Ltd.</P>
					<P>You may use Swimpion app and website for private and personal non-commercial use only, provided that all copyright, trademark and other proprietary notices contained in original materials on any copy of these materials is retained. You agree not to sell, resell, supply or otherwise the content or extracts or other information available in any manner to any third party without the prior written consent of Gama Software Ltd.</P>
					<H2>Viruses, hacking and other offences</H2>
					<P>You must not misuse Swimpion app or website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our app and/or website, the server on which our app and website are stored or any server, computer or database connected to our app and website. You must not attack our app and/or website via a denial-of-service attack or a distributed denial-of service attack.</P>
					<P>If you breach this provision, you will commit a criminal offence and we will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing any information we have about you to them. In the event of such a breach, your right to use our app and website will cease immediately.</P>
					<P>We make every reasonable effort to ensure that Swimpion app and website resources and download materials are virus free. We would recommend that all users of the Swimpion application and website use anti-virus software to screen downloads for Swimpion application and website. We will not be left liable for any issues, or loses, relating to data, or hardware, what may occur as a result of using material from Swimpion application or website.</P>
					<H2>Linking to and from our app and website</H2>
					<P>We do not accept responsibility, or reliability, of websites linked to from Swimpion application or website. Please do not view the inclusion of a link on our app or website as an endorsement. It should be clear that the websites linked from our app and/or website is out of our responsibility and editorial control.</P>
					<P>You may link to our website, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</P>
					<P>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</P>
					<P>You must not establish a link from any website that is not owned by you.</P>
					<P>If you would like to link to Swimpion website from an alternative website, you do not have to ask for permission. We do not, however, permit framing and pagejacking of Swimpion app or website.</P>
					<H2>Governing law</H2>
					<P>These Terms and Conditions and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Scotland.</P>
					<H2>Termination</H2>
					<P>We reserve the right to refuse access to Swimpion app and website to any user at our sole discretion.</P>
					<H2>Changes to terms and conditions</H2>
					<P>We may revise these Terms and Conditions at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. These Terms and Conditions were last updated on the 1<sup>st</sup> of October, 2023.</P>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}