import styled from 'styled-components';
import { StyleProps } from './types';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
`;

export const ImgWrapper = styled.div<StyleProps>`
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    ${props => props.marginTop && 'margin-top: ' + props.marginTop + 'px;'}
`;

export const Img = styled.img`
    width: 260px;

    @media only screen and (max-width: 1080px) {
        width: 280px;
    }

    @media only screen and (max-width: 700px) {
        width: 220px;
    }
`;