import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-3.webp';
import btScreen2 from '../../assets/screens/all/opt-4.png';
import btScreen3 from '../../assets/screens/all/opt-13.png';
import btScreen4 from '../../assets/screens/all/opt-14.png';


export const SwimTimeGoalsPage = () => {
	useScrollToTop();
	usePageTitle('Swim Time Goals - Set swim time goals');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Swim Time Goals</H1>
					<HeadingImg src={headerImg} alt="Stopwatch" />
					
					<H2>Set swim time goals</H2>
					<P>Set swim time goals for any stroke and distance. Writing goals down will keep you motivated and improve your swimming performance. You can compare your swim times to peers in your age group and World Record holders.</P>

					<ImgRow>
						<Img src={btScreen3} alt="App screenshot of Set Swim Time Goal page" />
						<Img src={btScreen2} alt="App screenshot of Swim Time Details page" />
						<Img src={btScreen4} alt="App screenshot of World Record holder page"/>
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}