import { BrowserRouter, Switch, Route } from "react-router-dom";
import { HomePage } from '../pages/Home';
import { AboutPage } from '../pages/About';
import { PrivacyPage } from '../pages/Privacy';
import { TermsPage } from '../pages/Terms';
import { NotFoundPage } from '../pages/NotFound';

import { WorkoutsPage } from '../pages/Workouts';
import { MeetsPage } from '../pages/Meets';
import { BestTimesPage } from '../pages/BestTimes';
import { SwimTimeGoalsPage } from '../pages/SwimTimeGoals';
import { SwimmingChallengesPage } from '../pages/SwimmingChallenges';
import { MonthlySwimmingGoalsPage } from '../pages/MonthlySwimmingGoals';
import { CalorieCalculator } from '../pages/CalorieCalculator';
import { MotivationalTimesPage } from '../pages/MotivationalTimes';
import { MultipleAccountsPage } from '../pages/MultipleAccounts';
import { TestimonialsPage } from '../pages/Testimonials';
import { DeleteAccountPage } from '../pages/DeleteAccount';

export const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} /> 
            <Route path="/privacy" component={PrivacyPage} /> 
            <Route path="/terms" component={TermsPage} /> 
            
            <Route path="/workouts" component={WorkoutsPage} />
            <Route path="/meets" component={MeetsPage} />
            <Route path="/best-times" component={BestTimesPage} />
            <Route path="/swim-time-goals" component={SwimTimeGoalsPage} />
            <Route path="/swimming-challenges" component={SwimmingChallengesPage} />
            <Route path="/monthly-swimming-goals" component={MonthlySwimmingGoalsPage} />
            <Route path="/swimming-calorie-calculator" component={CalorieCalculator} />
            <Route path="/motivational-times" component={MotivationalTimesPage} />
            <Route path="/multiple-accounts" component={MultipleAccountsPage} />
            <Route path="/testimonials" component={TestimonialsPage} />
            <Route path="/delete-account" component={DeleteAccountPage} />
            
            <Route component={NotFoundPage} />
        </Switch>
    </BrowserRouter>
)