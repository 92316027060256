import { Wrapper, Lines } from './styles';
import { HamburgerProps } from './types';

export const Hamburger = (props: HamburgerProps) => {
    const { active, onClick } = props;

    const clickHandler = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <Wrapper active={active} onClick={clickHandler} title="Click to expand navigation menu">
            <Lines active={active} />
        </Wrapper>
    )
}