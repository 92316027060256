import styled from 'styled-components';


export const Intro = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 9;
`;