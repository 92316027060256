import { AppConfig } from '../../../../../config';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { IAnalyticsProvider } from '../../types';

export const firebaseService = (): IAnalyticsProvider => {
    firebase.initializeApp(AppConfig.firebase);
    const analytics = firebase.analytics();
    
    const log = (eventName: string, data?: any) => {
        analytics.logEvent(eventName, data)
    }

    return {
        log
    }
}