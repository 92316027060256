import styled from 'styled-components';
import AppleStoreBtn from '../../../assets/app_store_btn.svg';
import GoogleStoreBtn from '../../../assets/google_play_btn.svg';
import { DownloadButtonProps } from './types';

export const Container = styled.div<DownloadButtonProps>`
    margin-top: 80px;
    margin-bottom: 100px;

    @media only screen and (max-width: 480px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 360px) {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    ${props => props.small && 'margin-top: 0px !important; margin-bottom: 0px !important;'}
    ${props => props.xSmall && 'margin-top: 0px !important; margin-bottom: 0px !important;'}
    ${props => props.noSpacing && 'margin: 0px !important; bottom: 0px !important;'}
`;


export const Wrapper = styled.div<DownloadButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 80px;
    padding: 0;

    ${props => props.small && 'margin-top: 0px;'}
    ${props => props.xSmall && 'margin-top: 0px !important; margin-bottom: 0px !important;'}
    ${props => props.noSpacing && 'margin: 0px !important; bottom: 0px !important;'}
`;


export const Box = styled.div<DownloadButtonProps>`
    background-color: #000;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    border-radius: 6px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-right: 20px;

        @media only screen and (max-width: 420px) {
            margin-right: 10px;
        }
    }
`;

export const Link = styled.a<DownloadButtonProps>`
    width: 200px;
    height: 75px;
    display: inline-flex;
    background-repeat: no-repeat;
    background-position: center;
    ${props => props.small && 'width: 140px; height: 40px;'}
    ${props => props.xSmall && 'width: 140px; height: 40px;'}

    &.app-store-btn {
        background-image: url(${AppleStoreBtn});
    }

    &.google-play-btn {
        background-image: url(${GoogleStoreBtn});
    }

    @media only screen and (max-width: 680px) {
        width: 160px; 
        height: 50px;
    }

    @media only screen and (max-width: 520px) {
        width: 140px; 
        height: 40px;
    }

    @media only screen and (max-width: 420px) {
        width: 110px; 
        height: 30px;
    }
`;

export const Description = styled.h2`
    padding: 0 20px;
    margin: 60px 0 100px 0;
    text-align: center;
    color: #1d1d1f;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 60px;

    @media only screen and (max-width: 760px) {
        font-size: 44px;
        line-height: 44px;
    }

    @media only screen and (max-width: 480px) {
        font-size: 34px;
        line-height: 34px;
    }

    @media only screen and (max-width: 360px) {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 50px;
    }
`;

export const Small = styled.span`
    font-size: 24px;

    @media only screen and (max-width: 760px) {
        font-size: 18px;
    }

    @media only screen and (max-width: 480px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 320px) {
        font-size: 14px;
    }
`;