import { AppConfig } from '../../../../../config';
import amplitude from 'amplitude-js';
import { IAnalyticsProvider } from '../../types';

export const amplitudeService = (): IAnalyticsProvider => {
    var analytics =  amplitude.getInstance();
    analytics.init(AppConfig.amplitude.apiKey, undefined, { 
        includeReferrer: true, 
        includeUtm: true, 
        includeFbclid: true, 
        includeGclid: true
    });
    
    const log = (eventName: string, data?: any) => {
        analytics.logEvent(eventName, data);
    }

    return {
        log
    }
}