import { IonIcon } from '@ionic/react';
import { logoApple, logoAndroid, mail } from 'ionicons/icons';

import headerImg from '../../assets/headers/opt-header-9.webp';
import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import {
	Testimonials,
	Box,
	Content,
	Source,
	Username,
	UserType,
	Title,
	Comment
} from './styles';
import { Stars } from './Stars'



export const TestimonialsPage = () => {
	useScrollToTop();
	usePageTitle('Testimonials - What our users are saying');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Testimonials</H1>
					<HeadingImg src={headerImg} alt="Bondi beach swimming" />				

					<Testimonials>
						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>David7649</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Great for athletic swimmers</Title>
								<Comment>I used to keep my swim times in my phone anyway, so this is super cool. Great app, easy to use and pretty.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Andra</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Nice app</Title>
								<Comment>This is a pretty nice app. Love the stats and how well the workouts are displayed - simple and clear and beautiful. I'm only missing the possibility of adding an image to my workout, otherwise loving it.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Kevin</Username>
								<UserType>(parent)</UserType>
								
								<Stars />
									
								<Title>Awesome app</Title>
								<Comment>This app is awesome! UI/UX is on point, you can keep track of your trainings, your meets, PB record and progression. You can even see what percentile your time is in!! It's free and fully functional. Developer is supper responsive, send them some love or donate if you can. Please stay Ad free as long as you can. My daughter and I use it to tack her progression, and since we started, several useful features appeared. Best app on the market.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Lucy</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Amazing app!</Title>
								<Comment>It’s a great way to track times across your swimming journey and see how you have improved, and to track you swimming sessions. As a competitive swimmer that hasn’t swam in a club for the past 10 months I think this all will really help me. I can can get to my times quick and easy. I can see how I improve across the season which I normally would write down in my book. Brilliant app!</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Lilly White</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Easy and useful!</Title>
								<Comment>Easy to use app. Easy to keep track of my times.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Jeandre Geldenhuys</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />

								<Comment>I want to applaud you on this great app. I have been looking for an app that does everything that your app does.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Darren</Username>
								<UserType>(parent)</UserType>
								
								<Stars />
									
								<Title>Wonderful app</Title>
								<Comment>I’m loving the app and will be recommended it to everyone at our local club and the swim events we attend going forward.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Joanne</Username>
								<UserType>(parent)</UserType>
								
								<Stars />
									
								<Title>Useful for a parent</Title>
								<Comment>I am enjoying using your app. As a swim parent of two swimmers I find it helpful to record meet info for more than one person.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Mary Rose</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Useful app</Title>
								<Comment>Find the app useful to track my swims.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Rahel</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Easy to use</Title>
								<Comment>I love this app! it’s very easy to understand this app.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Ahmed</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Best app for athletes</Title>
								<Comment>Best app for athletic swimmers You can add number of cycles and the distance of every training devolvement.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>ischumie</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Really good!</Title>
								<Comment>I’ve only been using this app for 5-10 mins- it’s really easy to use and I like how you can see how close you are to reaching your goal! The workout stats are also cool! :)</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Emily</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Outstanding app</Title>
								<Comment>Spectacular, outstanding app.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={mail} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Alliok</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Cool app</Title>
								<Comment>I really appreciate the simplicity of this app!</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Underrated app</Title>
								<Comment>I am a competitive swimmer and I literally use this app every day to log how much I swim during the training. I also log my swim times after meets. It’s a cool app, especially for club swimmers.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>TheWholigan</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Useful</Title>
								<Comment>It’s a neat app, very useful. I used to write my PBs on my phone before anyways so this is quite handy.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Richard 5757</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Simple, useful and free</Title>
								<Comment>I was a competitive swimmer many moons ago but I still swim regularly and check my swim times. I mainly use the workout feature which is very straightforward which I like. I used other apps but they are a bit too much for me and quite expensive. If this app had some kind of workouts library for old folks like me, it would probably be the best swim app I manage to find on the Internet.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>SwimmerAgnes</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Useful for competitive swimmers</Title>
								<Comment>"Best Time" feature is great as I can see how my times change and how much faster I get:) and i can set my goal times which is good if you need a specific time to enter certain swim meet. and i can keep all my swim meets in one place, even those that aren't covered by hy-tek. this app would be really useful for younger swimmers who do more local meets or development meets within the club and want to keep their swim times in one place.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Phillip</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Good for keeping times </Title>
								<Comment>I swim for a team and it helps me keep track of my races and old one and the place I got in it.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>AS</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Useful app</Title>
								<Comment>Simple to use. I use it for writing my swim times and it sorts my PBs which is good for pushing myself. I also log how much I swim. Calorie calculator is quite accurate.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>RS</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />

								<Comment>Good for swim times and meets.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoAndroid} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Poncho</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Comment>Super app cheers.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>kristenj12</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Perfect for manual tracking of workouts and meets</Title>
								<Comment>Excellent little app that does exactly what you need it to do without all the bells and whistles you don’t need. Developers are super responsive to feedback and quick to fix glitches. One thing would be nice is if the monthly analytics tracked changes in 100m pace over time.</Comment>
							</Content>
						</Box>

						<Box>
							<Source>
								<IonIcon icon={logoApple} style={{ fontSize: 28 }} />
							</Source>

							<Content>
								<Username>Sono-D</Username>
								<UserType>(swimmer)</UserType>
								
								<Stars />
									
								<Title>Beautiful App & Super Engaged Design Team</Title>
								<Comment>A beautiful uncluttered design making it quick & easy to use on the go whilst breaking your PB’s! A super attentive team behind the app who are always willing to listen to their users feedback and improve the user experience. Great work Swimpion team.</Comment>
							</Content>
						</Box>
					</Testimonials>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}