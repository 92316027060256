import styled from 'styled-components';
import { HashLink as NavLink } from 'react-router-hash-link';
import { LayoutStyleProps } from './types';


export const Wrapper = styled.div`
   padding: 50px 0px 0 0;

   @media only screen and (max-width: 480px) {
        padding: 20px 0 40px 0;
    }
`;

export const Content = styled.div<LayoutStyleProps>`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 9;

    ${props => props.marginTop && 'margin-top: ' + props.marginTop + 'px;'}
    ${props => props.bgColor && 'background-color: ' + props.bgColor + ';'}
`;

export const DownloadContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
`;

export const FooterContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px 0 0px;
    background-color: #222;
`;

export const LimitWidthContainer = styled.div`
   max-width: 680px;
   margin: auto;
   padding: 0 40px 50px 40px;
`;

export const ContactUsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFF;

    @media only screen and (max-width: 360px) {
        padding: 80px 0 0px;
    }
`;


export const H1 = styled.h1`
   font-family: "Poppins", sans-serif;
   font-weight: 700;
   font-size: 54px;
   line-height: 60px;
   text-align: left;
   color: #1d1d1f;
   margin: 20px 0 40px 0;

   @media only screen and (max-width: 480px) {
      font-size: 28px;
      line-height: 32px;
      margin-top: 10px;
      margin-bottom: 30px;
   } 
`;

export const H2 = styled.h2`
   font-family: "Poppins", sans-serif;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   text-align: left;
   color: #1d1d1f;
   margin: 30px 0 10px 0;
`;

export const H3 = styled.h3`
   font-family: "Poppins", sans-serif;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   text-align: left;
   color: #1d1d1f;
   margin: 30px 0 10px 0;
`;

export const P = styled.p`
   font-family: Karla;
   font-weight: 300;
   font-style: normal;
   font-size: 18px;
   line-height: 24px;
   color: #404040;

   @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 22px;
   }
`;

export const UL = styled.ul`
   font-family: Karla;
   font-weight: 300;
   font-style: normal;
   font-size: 18px;
   line-height: 24px;
   color: #404040;

   li {
      padding-bottom: 10px;
   }

   @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 22px;

      li {
         padding-bottom: 5px;
      }
   }
`;

export const HeadingScreen = styled.div`
    position: relative;
    height: 360px;
    width: 100%;
    background-color: #fff;
    border-radius: 18px;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
        width: 300px;
        padding: 60px 20px 20px 60px;

        @media only screen and (max-width: 420px) {
            width: 90%;
        }
    }
`;

export const HeadingImg = styled.img`
   height: 320px;
   width: 100%;
   border-radius: 18px;
   object-fit: cover;
   margin-bottom: 20px;

   @media only screen and (max-width: 480px) {
      margin-bottom: 10px;
   }
`;

export const BackLink = styled(NavLink)`
   font-family: Karla;
   font-weight: 400;
   font-style: normal;
   line-height: 24px;
   color: #06c;
   margin: 0;
   display: block;
   text-decoration: none;
   margin: 15px 0 0 0;
`;

export const ImgRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;

   @media only screen and (max-width: 760px) {
      flex-direction: column;
      align-items: center;
   }
`;

export const Img = styled.img`
   width: 300px;
   padding: 20px;

   @media only screen and (max-width: 480px) {
      width: 70%;
   }
`;

export const Row = styled.div`
   display: flex;
   flex-direction: row;
`;

export const Col = styled.div`
   display: flex;
   flex-direction: column;
`;

export const Spacer = styled.div`
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;