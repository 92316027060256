import React, { createContext } from "react";
import { Services } from '../../services'

const DependenciesContext = createContext({ Services });

const DependenciesProvider = (props: any) => {
    const value = { Services }

    return (
        <DependenciesContext.Provider value={value}>
            {props.children}
        </DependenciesContext.Provider>
    )
}

export {
    DependenciesContext,
    DependenciesProvider
};