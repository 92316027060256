import styled from 'styled-components';
import { HamburgerProps } from './types';

export const Wrapper = styled.button<HamburgerProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 48px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
`;

export const Lines = styled.span<HamburgerProps>`
    display: block;
    position: absolute;
    top: 24px;
    left: 11px;
    right: 9px;
    height: 2px;
    background-color: #000;

    ${props => props.active && 'background-color: transparent;'}

    ::before, ::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
        content: '';
        transition: transform .2s;
    }

    ::before {
        top: -8px;
        ${props => props.active && 'top: 0; transform: rotate(45deg);'}
    }

    ::after {
        bottom: -8px;
        ${props => props.active && 'bottom: 0; transform: rotate(-45deg);'}
    }
`;
