import { amplitudeService } from './providers/amplitude';
import { firebaseService } from './providers/firebase';
import { IAnalyticsProvider } from './types';

export const analyticsService = (): IAnalyticsProvider => {
    const amplitude = amplitudeService();
    const firebase = firebaseService();

    return {
        log: (eventName: string, data?: any) => {
            try{
                amplitude.log(eventName, data);
                firebase.log(eventName, data);
            } catch (e) {
                console.log(e);
            }
        }
    }
}