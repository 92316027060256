import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.header`
    display: flex;
    flex-direction: row;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 0px;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    align-items: center;
    height: 70px;
    padding: 5px 40px 0 40px;
    margin: 0 auto;
`;

export const RightSide = styled.div`
    margin-left: auto;
`;

export const LogoText = styled.img`
    width: 110px;
    display: inherit;
`;

export const Nav = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0 0 0 50px;
    text-align: center;

     @media only screen and (max-width: 760px) {
        display: none;
    }
`;

export const Item = styled.li`
    margin-left: 15px;
    position: relative;

    :first-child {
        margin-left: 0;
    }

    :hover {
        .subMenu {
            display: flex;
        }
    }
`;

export const Link = styled(NavLink)`
    color: #333;
    font-family: "Karla";
    font-weight: 300;
    text-decoration: none;
    padding: 8px 10px;
    font-size: 16px;

    :hover {
        color: #000;
    }

    :first-child {
        padding-left: 0;
        margin-left: 0;
    }
`;

export const SubMenu = styled.ul`
    position: absolute;
    top: 41px;
    left: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    display: none;
    flex-direction: column;
    max-width: 160px;
    width: 160px;
    margin: 0 auto;
    padding: 20px;
    align-items: flex-start;
    background-color: #fff;
    
    li {
        margin: 0;
        padding: 4px 0;
        list-style: none;
    }
`;
