import styled from 'styled-components';


export const TextRed = styled.span`
   color: #FF4B3B;
`;

export const Img = styled.img`
   width: 350px;
   box-shadow: -1px 2px 4px 0px rgb(0 0 0 / 20%);
   transform: rotate(9deg);
   border-radius: 8px;
   margin: 40px 0 60px 0;

   @media only screen and (max-width: 480px) {
      width: 90%;
   }
`;