import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H3, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-8.webp';
import screen2 from '../../assets/screens/all/opt-4.png';
import screen8 from '../../assets/screens/all/opt-15.png';


export const MotivationalTimesPage = () => {
	useScrollToTop();
	usePageTitle('Motivational Times - Compare your swim times with other swimmers');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Motivational Times</H1>
					<HeadingImg src={headerImg} alt="Boy swimming in the pool" />
					
					
					<P>Compare your swim times with other swimmers from all over the world (in same age group, gender, particular stroke and distance).</P>
					<P>Motivational times give you an indication how you perform among your age group swimmers.  Check if you are within the top 6% of fastest swimmers in the world.</P>

					<ImgRow>
						<Img src={screen2} alt="App screenshot of Swim by stoke progress page" />
						<Img src={screen8} alt="App screenshot of Motivational times page" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}