import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html {
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F6F8FA;
        overflow-x: hidden;
    }

    #root {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
        margin-top: 70px;
    }

    a {
        color: #06c;
        text-decoration: none;

        &:hover {
        }
    }

    /* Paypal Donate Buton style owerride */
    #donate-button-container img {
        max-width: 340px;

        @media only screen and (max-width: 420px) {
            max-width: 270px;
        }
    }
`;

export {
    GlobalStyle
}