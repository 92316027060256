import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-5.webp';
import screen5 from '../../assets/screens/all/opt-1.png';
import screen6 from '../../assets/screens/all/opt-6.png';
import screen1 from '../../assets/screens/all/opt-16.png';
import screen2 from '../../assets/screens/all/opt-17.png';


export const SwimmingChallengesPage = () => {
	useScrollToTop();
	usePageTitle('Swimming Challenges - Choose a swimming challenge');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Swimming Challenges</H1>
					<HeadingImg src={headerImg} alt="Swimming in open watter" />
					
					<H2>Add fun to your swim training with our challenges</H2>
					<P>To make your swim trainings more interesting and fun, we created a number of swimming challenges for you to choose from.</P>
					
					<ImgRow>
						<Img src={screen5} alt="App screenshot of My Workouts page" />
						<Img src={screen6} alt="App screenshot of My Challenges page" />
					</ImgRow>
					
					<P>Choose from various distances, some short, some taking months to complete. The distances are geographically accurate so even if you swim laps in your swimming pool, you can imagine that you swim in one of the beautiful locations that we hand-picked for you.</P>

					<ImgRow>
						<Img src={screen2} alt="App screenshot of in progress Challenge Details page" />
						<Img src={screen1} alt="App screenshot of Challenge Details page" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}