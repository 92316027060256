import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 80px;
    padding: 15px 30px 10px 30px;

    @media only screen and (max-width: 680px) {
        margin-top: 40px;
    }
`;

export const AppFrame = styled(NavLink)`
    text-decoration: none;
    display: inline-flex;
    width: 70px;
    height: 70px;
    border-radius: 16px;
    justify-content: center;
    background-color: #0F1420;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);

    @media only screen and (max-width: 680px) {
        width: 60px;
        height: 60px;
    }
`;

export const Logo = styled.img`
    width: 50px;
    margin-left: 5px;

    @media only screen and (max-width: 680px) {
        width: 45px;
    }
`;