import { Wrapper, Row, Nav, Item, Link, SocialLink, SocialIcon, A } from './styles';
import instaLogo from '../../../../../assets/social/insta.svg';
import twitterLogo from '../../../../../assets/social/twitter.svg';
import xLogo from '../../../../../assets/social/X_icon.svg';
import { useDependencies } from '../../../../hooks';


export const Footer = () => {
    const { analytics } = useDependencies();

    const onLinkClick = (linkName: string) => {
        analytics.log(`LinkClicked_Footer_${linkName}`);
    }

    return (
        <Wrapper>
            <Row>
                <Nav>
                    <Item>
                        <Link to="/about" onClick={() => onLinkClick('/privacy')} title="Navigate to About Us page">About</Link>
                    </Item>

                    <Item>
                        <Link to="/testimonials" onClick={() => onLinkClick('/testimonials')} title="Navigate to Testimonials page">Testimonials</Link>
                    </Item>

                    <Item>
                        <A href="mailto:info@swimpion.com" onClick={() => onLinkClick('/contact-us')} title="Write us an email">Contact Us</A>
                    </Item>

                    <Item>
                        <Link to="/privacy" onClick={() => onLinkClick('/privacy')} title="Navigate to Privacy Policy page">Privacy Policy</Link>
                    </Item>
                    
                    <Item>
                        <Link to="/terms" onClick={() => onLinkClick('/terms')} title="Navigate to Terms & Conditions page">Terms & Conditions</Link>
                    </Item>
                </Nav>

                <Nav right>
                    <Item>
                        <SocialLink href="https://www.instagram.com/iamswimpion" target="_blank" title="Visit our Instagram feed">
                            <SocialIcon src={instaLogo} alt="Instagram logo" />
                        </SocialLink>
                    </Item>
                    <Item>
                        <SocialLink href="https://twitter.com/iamSwimpion" target="_blank" title="Visit our X feed">
                            <SocialIcon src={xLogo} alt="X logo" />
                        </SocialLink>
                    </Item>
                </Nav> 
            </Row>
        </Wrapper>
    )
}