import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P, UL,
	ImgRow, Img,
	BackLink, HeadingImg
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-1.webp';
import screen1 from '../../assets/screens/all/opt-10.png';
import screen2 from '../../assets/screens/all/opt-5.png';
import screen4 from '../../assets/screens/all/opt-9.png';
import screen5 from '../../assets/screens/all/opt-7.png';


export const MeetsPage = () => {
	useScrollToTop();
	usePageTitle('Meets - Keep swim meet results organised');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Meets</H1>
					<HeadingImg src={headerImg} alt="People swimming" />
										
					<H2>Keep swim meet information and results in one place</H2>
					<P>A useful tool for competitive swimmers (or their parents) to keep results from swim meets or competitions organised and in one place. If you are a non-competing swimmer, use it for recording your swim times instead.</P>
					
					<ImgRow>
						<Img src={screen2} alt="App screenshot of My Meets page" />
						<Img src={screen1} alt="App screenshot of Meet Details page" />
					</ImgRow>

					<UL>
						<li>All strokes and distances that you swim in that swim meet,</li>
						<li>Swimming times achieved,</li>
						<li>FINA points earned,</li>
						<li>Split times,</li>
						<li>Places/medals received,</li>
						<li>Date of the swim meet,</li>
						<li>Meets over multiple days,</li>
						<li>Swimming meet name and location,</li>
						<li>Swimming pool size.</li>
					</UL>

					<P>You can pre-enter your swim meet info and simply add your swim times later.</P>

					<ImgRow>
						<Img src={screen4} alt="App screenshot of Add Swim page" />
						<Img src={screen5} alt="App screenshot of Swim Details page" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}