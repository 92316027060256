import { useEffect } from 'react';
import { Wrapper, Inner, ContentBlock, Title, Nav, Item, Link, SocialLink, SocialIcon, A } from './styles';
import { useDependencies } from '../../../../hooks';
import { DownloadButtons } from '../../../DownloadButtons';
import instaLogo from '../../../../../assets/social/insta-dark.svg';
import twitterLogo from '../../../../../assets/social/twitter-dark.svg';
import { PaypalDonateButton } from '../PaypalDonateButton';
import { ModalNavProps } from './types';


export const ModalNavigation = (props: ModalNavProps) => {
    const { onCloseModal } = props;
    const { analytics } = useDependencies();

    useEffect(() => {
        analytics.log('ModalNavigation_Viewed');

        return () => {
            /** 
             * Clean up function 
             * When this component goes out of Scope then
             * Remove scroll restrictions on the BODY element
            */
            document.body.style.overflow = '';
        }
    }, [])

    const navigationInterceptor = () => {
        onCloseModal();
    }

    return (
        <Wrapper>
            <Inner>
                <Nav>
                    <Item>
                        <Title>Features</Title>
                    </Item>

                    <Item>
                        <Link to="/meets" title="Navigate to Meets page" onClick={navigationInterceptor}>Meets</Link>
                    </Item>

                    <Item>
                        <Link to="/best-times" title="Navigate to Best Times page" onClick={navigationInterceptor}>Best Times</Link>
                    </Item>

                    <Item>
                        <Link to="/swim-time-goals" title="Navigate to Swim Goals page" onClick={navigationInterceptor}>Swim Goals</Link>
                    </Item>

                    <Item>
                        <Link to="/motivational-times" title="Navigate to Motivational Times page" onClick={navigationInterceptor}>Motivational Times</Link>
                    </Item>

                    <Item>
                        <Link to="/multiple-accounts" title="Navigate to Multiple Accounts page" onClick={navigationInterceptor}>Multiple Accounts</Link>
                    </Item>

                    <Item>
                        <Link to="/workouts" title="Navigate to Workouts page" onClick={navigationInterceptor}>Workouts</Link>
                    </Item>

                    <Item>
                        <Link to="/swimming-challenges" title="Navigate to Challenges page" onClick={navigationInterceptor}>Challenges</Link>
                    </Item>

                    <Item>
                        <Link to="/monthly-swimming-goals" title="Navigate to Monthly Goals page" onClick={navigationInterceptor}>Monthly Goals</Link>
                    </Item>

                    <Item>
                        <Link to="/swimming-calorie-calculator" title="Navigate to Calorie Calculator page" onClick={navigationInterceptor}>Calorie Calculator</Link>
                    </Item>

                    <Item isheading={true}>
                        <Link to="/about" title="Navigate to About Us page" onClick={navigationInterceptor}>About Us</Link>
                    </Item>

                    <Item isheading={true}>
                        <Link to="/testimonials" title="Navigate to Testimonials page" onClick={navigationInterceptor}>Testimonials</Link>
                    </Item>
                </Nav>

                
                <ContentBlock>
                    <DownloadButtons small={true} showTitle={false} noSpacing={true} />
                </ContentBlock>


                <ContentBlock>
                    <PaypalDonateButton />
                </ContentBlock>


                <ContentBlock>
                    <Nav direction='row'>
                        <Item>
                            <SocialLink href="https://www.instagram.com/iamswimpion" target="_blank" title="Check our Instagram feed">
                                <SocialIcon src={instaLogo} alt="Instagram icon" />
                            </SocialLink>
                        </Item>
                        <Item>
                            <SocialLink href="https://twitter.com/iamSwimpion" target="_blank" title="Check our Twitter feed">
                                <SocialIcon src={twitterLogo} alt="Twitter icon" />
                            </SocialLink>
                        </Item>
                    </Nav>
                </ContentBlock>


                <Nav>
                    <Item>
                        <A href="mailto:info@swimpion.com" onClick={() => analytics.log(`Clicked_ContactUs`)} title="Write us an email">Contact Us</A>
                    </Item>

                    <Item>
                        <Link to="/privacy" title="Navigate to Privacy Policy page" onClick={navigationInterceptor}>Privacy Policy</Link>
                    </Item>
                    
                    <Item>
                        <Link to="/terms" title="Navigate to Terms & Conditions page" onClick={navigationInterceptor}>Terms & Conditions</Link>
                    </Item>
                </Nav> 
            </Inner>
        </Wrapper>
    )
}