import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img, UL,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-7.webp';
import workoutsScreen4 from '../../assets/screens/all/opt-12.png';


export const CalorieCalculator = () => {
	useScrollToTop();
	usePageTitle('Swimming Calorie Calculator - Created specifically for swimming');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Swimming Calorie Calculator</H1>
					<HeadingImg src={headerImg} alt="Calories" />
					
					<H2>Created specifically for swimmers</H2>
					<P>Our calorie calculator was specifically designed for swimming and will give you a personal estimate of how many calories you burn during your swimming sessions.</P>
					<P>Our calorie calculator formula takes into account:</P>
					<UL>
						<li>Your weight</li>
						<li>Gender</li>
						<li>Swimming minutes</li>
						<li>The distance you swim</li>
						<li>Your swim pace (with resting breaks adjusted*)</li>
						<li>Universally approved Metabolic Equivalents (METs*)</li>
					</UL>
					
					<ImgRow>
						<Img src={workoutsScreen4} alt="App screenshot of workout sessions with calculated calories" />
					</ImgRow>

					<P>Please note that the number of calories burned is only an estimate. There are many variables that influence calories burned while swimming, such as your heart rate, genetics, and even water temperature! So unless you are in a research lab and have access to a metabolic cart, you will likely to receive only an estimated value of your calories burned.</P>
					<P>* We used existing guidelines for MET values, as approved by World Health Organisation and physiologists worldwide.</P>
					<P>* Break time is an estimated average.</P>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}