import { useEffect } from "react";
import { Wrapper } from "./styles";
import donateButton from '../../../../../assets/buyUsCoffeeButton.png'

declare var PayPal: any;

const loadScriptAsync = (url: string, callback: Function) => {
    var s: any = document.createElement('script');
    s.setAttribute('src', url);
    s.setAttribute('id', 'paypal-sdk');
    s.onload = callback;
    document.head.insertBefore(s, document.head.firstElementChild);
}

const initDonateButton = () => {
    PayPal?.Donation?.Button({
        env:'production',
        hosted_button_id:'5UU7J5TK5YZMS',
        image: {
            src: donateButton,
            alt:'Donate with PayPal button',
            title:'PayPal - The safer, easier way to pay online!',
        }
    }).render('#donate-button-container');
}

export const PaypalDonateButton = () => {

    useEffect(() => {
        const scriptExists = document.getElementById('paypal-sdk');
        
        if(!scriptExists){
            loadScriptAsync('https://www.paypalobjects.com/donate/sdk/donate-sdk.js', initDonateButton);
        } else {
            initDonateButton();
        }
    }, [])

    return (
        <Wrapper id="donate-button-container" />
    )
}