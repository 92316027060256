import styled from 'styled-components';
import { DescriptionStyleProps } from './types';


export const Container = styled.div<DescriptionStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    height: 450px;
    overflow-y: hidden;
    ${props => props.isNext ? '' : 'background-color: #fff;'}

    @media only screen and (max-width: 760px) {
        height: 800px;
    }

    @media only screen and (max-width: 420px) {
        height: 700px;
    }
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 40px;
`;

export const Row = styled.div<DescriptionStyleProps>`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 760px) {
        ${props => props.isNext ? 'flex-direction: column-reverse;' : 'flex-direction: column;'}
    }
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const ContentWrapper = styled(Col)<DescriptionStyleProps>`
    @media only screen and (max-width: 960px) {
        ${props => props.isNext ? 'padding-left: 40px': 'padding-right: 40px;'}
    }

    @media only screen and (max-width: 760px) {
        padding: 150px 0 50px 0;
    }
`;

export const Title = styled.h2`
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 60px;
    margin: 0;
    padding: 0 0 10px 0;

    @media only screen and (max-width: 960px) {
        font-size: 54px;
        line-height: 50px;
    }

    @media only screen and (max-width: 760px) {
        font-size: 64px;
        line-height: 60px;
    }

    @media only screen and (max-width: 420px) {
        font-size: 44px;
        line-height: 40px;
    }
`;

export const Small = styled.span`
    font-size: 34px;

    @media only screen and (max-width: 420px) {
        font-size: 24px;
    }
`;

export const Content = styled.p`
    font-family: "Karla", sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0px;

    @media only screen and (max-width: 760px) {
        padding-bottom: 10px;
    }

    @media only screen and (max-width: 420px) {
        font-size: 16px;
    }
`;

export const ImgWrapper = styled(Col)`
    margin-top: 260px;
    align-items: center;

    @media only screen and (max-width: 760px) {
        margin-top: 0%;
    }
`;

export const Img = styled.img`
    margin: 0;
    border-radius: 20px;
    width: 340px;

    @media only screen and (max-width: 420px) {
        width: 100%;
    }
`;