import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P, UL,
	ImgRow, Img,
	BackLink, HeadingImg
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-2.webp';
import btScreen3 from '../../assets/screens/all/opt-4.png';
import btScreen5 from '../../assets/screens/all/opt-3.png';


export const BestTimesPage = () => {
	useScrollToTop();
	usePageTitle('Best Times - Track your best swimming times');
	

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Best Times</H1>

					<HeadingImg src={headerImg} alt="Boy swimming breaststroke" />
					
					<H2>Track your best swimming times</H2>
					
					<P>Our app automatically displays your best swim times for all strokes and distances.</P>
					
					<UL>
						<li>Visual presentation of your swim time progression,</li>
						<li>Motivational chart compares your times to peers in your age group,</li>
						<li>Let’s you set and track swim time goals,</li>
						<li>Shows the difference between your goals and actual times,</li>
						<li>Shows FINA points earned,</li>
						<li>Compares your swim times to the swim times of World Record holders.</li>
					</UL>

					<ImgRow>
						<Img src={btScreen5} alt="App screenshot of My best times" />
						<Img src={btScreen3} alt="App screenshot of Swim time progress by stroke" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}