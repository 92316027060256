import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ModalNavStyleProps } from './types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    justify-content: center;
`;

export const Inner = styled.div`
    height: 100%;
    overflow: scroll;
    padding-bottom: 100px;
    padding-top: 50px;
`;

export const ContentBlock = styled.div`
    padding: 15px 0;
`;

export const DonateButtonWrapper = styled.div`
    padding: 0 0 15px 0;
`;

export const Nav = styled.ul<ModalNavStyleProps>`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0 0 20px 0;
    justify-content: center;
    text-align: center;

    li {
        margin: 0;
        padding: 0;
    }

    ${props => props.direction && 'flex-direction: ' + props.direction + ';'}
`;


export const Item = styled.li<ModalNavStyleProps>`
    margin: 0;
    padding: 0;

    a {
        ${props => props.isheading && 'font-size: 28px; padding: 10px 0 0 0; font-family: "Poppins"; font-weight: 600;'}
    }
`;


export const Link = styled(NavLink)<ModalNavStyleProps>`
    color: #000;
    font-family: "Karla";
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: inline-block;
    padding: 4px;

    :hover {
        color: #666;
    }
`;


export const SocialLink = styled.a`
    display: inline-block;
    padding: 10px;
`;

export const SocialIcon = styled.img`
    width: 32px;
    fill: #000;
`;

export const A = styled.a`
    color: #000;
    font-family: "Karla";
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: inline-block;
    padding: 4px;

    :hover {
        color: #666;
    }
`;

export const Title = styled.p`
    color: #000;
    text-decoration: none;
    font-size: 28px;
    margin: 0;
    padding: 10px 0 0px 0;

    font-family: "Poppins"; 
    font-weight: 600;
`;