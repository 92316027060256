import { useDependencies } from '../../hooks';
import { Container, Wrapper, Link, Description, Box, Small } from './styles';
import { DownloadButtonProps } from './types';

export const DownloadButtons = (props: DownloadButtonProps) => {
	const { small = false, showTitle = true, xSmall = false, noSpacing } = props;
	const { analytics } = useDependencies();
    
    const onLinkClick = (appStore: string) => {
        analytics.log(`DownloadLinkClicked_${appStore}`)
    }
		
	return (
		<Container small={small} xSmall={xSmall} noSpacing={noSpacing}>
			{showTitle && 
				<Description>
					<Small>Take your swimming to</Small><br />The Next Level.
				</Description>
			}

			<Wrapper small={small} xSmall={xSmall} noSpacing={noSpacing}>
				<Box><Link title="Navigate to Apple App Store to download Swimpion app" small={small} xSmall={xSmall} href="https://apps.apple.com/gb/app/swimpion/id1565422463" className="app-store-btn" onClick={() => onLinkClick('APPLE')}></Link></Box>
				<Box><Link title="Navigate to Google Play Store to download Swimpion app" small={small} xSmall={xSmall} href="https://play.google.com/store/apps/details?id=com.swimpion" className="google-play-btn" onClick={() => onLinkClick('GOOGLE')}></Link></Box>
			</Wrapper>
		</Container>
	)
}