import styled from 'styled-components';

export const Testimonials = styled.div`
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media only screen and (max-width: 680px) {
       flex-direction: column;
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 18px;
    width: 40%;
    gap: 10px;
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.04);

    @media only screen and (max-width: 680px) {
        width: auto;
        gap: 0px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Source = styled.div`
    
`;

export const Username = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Poppins",sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
`;

export const UserType = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Karla", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
`;

export const Title = styled.h3`
    margin: 20px 0 0 0;
    padding: 0;
    font-family: "Poppins",sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    text-align: center;
`;

export const Comment = styled.p`
    margin: 10px 0 0 0;
    padding: 0 0 20px 0;
    font-family: "Karla", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #000;
    text-align: center;
`;
