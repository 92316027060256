import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/opt-header-6.webp';
import screen2 from '../../assets/screens/all/opt-18.png';
import screen8 from '../../assets/screens/all/opt-19.png';


export const MonthlySwimmingGoalsPage = () => {
	useScrollToTop();
	usePageTitle('Monthly Swimming Goals - Set monthly distance target');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Monthly Swimming Goals</H1>
					<HeadingImg src={headerImg} alt="Swimming in the pool" />
					
					<H2>Set a monthly distance target to challenge yourself</H2>
					
					<P>While your training distances are often set by coaches, this monthly distance goal can be valuable during holiday breaks as it encourages regular exercise.</P>
					<P>Monthly distance goal is especially helpful for swimmers who swim for fitness rather than competitively.</P>

					<ImgRow>
						<Img src={screen2} alt="App screenshot of Setting workout monthly goal page" />
						<Img src={screen8} alt="App screenshot of Workout monthly summary page" />
					</ImgRow>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}