import { Container, Inner, Row, ContentWrapper, Title, Small, Content, ImgWrapper, Img } from './styles';
import { Button } from '../../../../shared/components/Button';
import meetSreen2 from '../../../../assets/screens/all/opt-5.png';
import workoutsScreen1 from '../../../../assets/screens/all/opt-1.png';
import bestTimesScreen from '../../../../assets/screens/all/opt-3.png';
import motivationalTimes from '../../../../assets/screens/all/opt-4.png';
import calorieCalculatorScreen from '../../../../assets/screens/all/opt-12.png';
import challengesScreen from '../../../../assets/screens/all/opt-6.png';


export const FeatureList = () => (
	<>
		<Container>
			<Inner>
				<Row>
					<ContentWrapper>
						<Title>
							<Small>Swim</Small><br />Meets.
						</Title>

						<Content>
							Useful tool for competitive swimmers to keep results from swim meets and competitions organised. If you are a non-competing swimmer, use it for recording your swim times only.
						</Content>

						<Button href="/meets" linkText='Explore' title="Navigate to Meets page" />
					</ContentWrapper>

					<ImgWrapper>
						<Img src={meetSreen2} alt="App screenshot of My Meets page" />
					</ImgWrapper>
				</Row>
			</Inner>
		</Container>


		<Container isNext={true}>
			<Inner>
				<Row isNext={true}>
					<ImgWrapper>
						<Img src={bestTimesScreen} alt="App screenshot of My Best Times page" />
					</ImgWrapper>

					<ContentWrapper isNext={true}>
						<Title>
							<Small>Best</Small><br />Times.
						</Title>

						<Content>
							Our app automatically displays your best swim times for all strokes and distances.
						</Content>

						<Button href="/best-times" linkText='Explore' title="Navigate to Best Times page" />
					</ContentWrapper>
				</Row>
			</Inner>
		</Container>


		<Container>
			<Inner>
				<Row>
					<ContentWrapper>
						<Title>
							<Small>Swim</Small><br />Workouts.
						</Title>

						<Content>
							Keep records of your training: log session duration and distance and check your swim pace and calories burned.
						</Content>

						<Button href="/workouts" linkText='Explore' title="Navigate to Workouts page" />
					</ContentWrapper>

					<ImgWrapper>
						<Img src={workoutsScreen1} alt="App screenshot of  My Workouts page" />
					</ImgWrapper>
				</Row>
			</Inner>
		</Container>


		<Container isNext={true}>
			<Inner>
				<Row isNext={true}>
					<ImgWrapper>
						<Img src={motivationalTimes} alt="App screenshot of Motivational Times page" />
					</ImgWrapper>

					<ContentWrapper isNext={true}>
						<Title>
							<Small>Motivational</Small><br />Times.
						</Title>

						<Content>
							Compare your swim times with other swimmers from all over the world in same age group, gender, particular stroke and distance.
						</Content>

						<Button href="/motivational-times" linkText='Explore' title="Navigate to Motivational Times page" />
					</ContentWrapper>
				</Row>
			</Inner>
		</Container>


		<Container>
			<Inner>
				<Row>
					<ContentWrapper>
						<Title>
							<Small>Calorie</Small><br />Calculator.
						</Title>

						<Content>
							Our calorie calculator was specifically designed for swimming and will give you a personal estimate of how many calories you burn during your swimming sessions. 
						</Content>

						<Button href="/swimming-calorie-calculator" linkText='Explore' title="Navigate to Calorie Calculator page" />
					</ContentWrapper>

					<ImgWrapper>
						<Img src={calorieCalculatorScreen} alt="App screenshot of Calorie Calculator page" />
					</ImgWrapper>
				</Row>
			</Inner>
		</Container>


		<Container isNext={true}>
			<Inner>
				<Row isNext={true}>
					<ImgWrapper>
						<Img src={challengesScreen} alt="App screenshot of Challenges page" />
					</ImgWrapper>

					<ContentWrapper isNext={true}>
						<Title>
							<Small>Swimming</Small><br />Challenges.
						</Title>

						<Content>
							To make your swim trainings more interesting and fun, we created a number of swimming challenges for you to choose from.
						</Content>

						<Button href="/swimming-challenges" linkText='Explore' title="Navigate to Swimming Challenges page" />
					</ContentWrapper>
				</Row>
			</Inner>
		</Container>
	</>
)