import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, P,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

export const NotFoundPage = () => {
	useScrollToTop();
	usePageTitle('Page Not Found - 404');
	
	return (
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>404 Not Found</H1>
					<HeadingImg src="https://images.unsplash.com/photo-1589652717521-10c0d092dea9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="Sad dog" />
					<P>404 Page with this path doesn't exist :(</P>

				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}
