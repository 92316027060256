import { Wrapper, ImgWrapper, Img } from './styles';
import workoutsScreen1 from '../../../../assets/screens/all/opt-1.png';
import bestTimesScreen from '../../../../assets/screens/all/opt-3.png';
import challengesSreen1 from '../../../../assets/screens/all/opt-6.png';
import meetSreen2 from '../../../../assets/screens/all/opt-5.png';
import meetSreen3 from '../../../../assets/screens/all/opt-7.png';
import { useEffect, useState } from 'react';


export const AppImages = () => {
	const [imgTopPosition, setImgTopPosition] = useState(60);

	const onScroll = () => {
		if (window.scrollY <= 540) {
			/** To make images scroll slower / 9 */
			const topPosition = 60 - (window.scrollY / 9);
			setImgTopPosition(topPosition)
		}
    }

    useEffect(() => {
        addEventListener("scroll", onScroll);

		/**
		 * Set initial Scroll position on first load
		 */
		onScroll();
		
        return () => {
            removeEventListener("scroll", onScroll);
        }
    }, [])
	
	return (
		<Wrapper>
			<ImgWrapper marginTop={imgTopPosition}>
				<Img src={challengesSreen1} alt="App screenshot of My Challenges page" />
			</ImgWrapper>

			<ImgWrapper marginTop={0}>
				<Img src={workoutsScreen1} alt="App screenshot of My Workouts page" />
			</ImgWrapper>

			<ImgWrapper marginTop={imgTopPosition}>
				<Img src={meetSreen2} alt="App screenshot of My Meets page" />
			</ImgWrapper>

			<ImgWrapper marginTop={0}>
				<Img src={bestTimesScreen} alt="App screenshot of My Best Times page" />
			</ImgWrapper>

			<ImgWrapper marginTop={imgTopPosition}>
				<Img src={meetSreen3} alt="App screenshot of Swim Details page" />
			</ImgWrapper>
		</Wrapper>
	)
}