import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import { TextRed, Img } from './styles';
import polaroid from '../../assets/polaroid.png';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, P,
	ImgRow, 
	BackLink,
} from '../../shared/components/Layout';


export const AboutPage = () => {
	useScrollToTop();
	usePageTitle('About Us');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>
					
					<H1>About Us</H1>

					<P>I'm Greta, a teen swimmer who's been passionate about swimming since childhood. Besides swimming, I enjoy travelling and reading.</P>
					<P>I created the Swimpion app because I couldn't find one made specifically for competitive swimmers. I wanted to record my PBs, track my swim times, set time goals, and keep results from swim meets in one place. My coach managed my workouts but I still wanted to track how much I swam every training session. All that is exactly what my app does!</P>
					
					<ImgRow>
						<Img src={polaroid} alt="Girl with a book" />
					</ImgRow>
					
					<P>My mum and dad help me with app improvements and management due to my busy schedule with school and swimming practices. And all of you, my fellow swimmers, who send me ideas and suggestions, you too help me improve my app!</P>
					<P>Thanks for reading. Hope you will download and check out my app if you haven’t already!</P>
					
					<P>With love,</P>
					<P>Greta <TextRed>♥</TextRed></P>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}