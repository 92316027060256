import { Btn } from './styles'
import { ButtonProps } from './types'

export const Button = (props: ButtonProps) => {
	const { href, linkText, size, title } = props;

	return (
		<Btn to={href} size={size} title={title ? title : ''}>
			{linkText}
		</Btn>
	)
}