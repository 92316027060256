import { usePageTitle, useScrollToTop } from '../../shared/hooks';
import {
	Layout, 
	ContentBlock, 
	LimitWidthContainer,
	H1, H2, P,
	ImgRow, Img, UL,
	BackLink, HeadingImg,
} from '../../shared/components/Layout';

import headerImg from '../../assets/headers/header-11.webp';


export const DeleteAccountPage = () => {
	useScrollToTop();
	usePageTitle('Delete Account');

	return(
		<Layout>
			<ContentBlock>
				<LimitWidthContainer>
					<BackLink to="/" title="Navigate back to Home page">&#8592; Back</BackLink>

					<H1>Delete Account</H1>
					<HeadingImg src={headerImg} alt="Swimmer" />
					
					<P>To delete your Swimpion account, you can either do it directly through the Swimpion app or simply send us an email from the email address that you used to register your Swimpion account with and we will delete it for you.</P>
					<P>You can reach us at <a href="mailto:info@swimpion.com">info@swimpion.com</a>.</P>
				</LimitWidthContainer>
			</ContentBlock>
		</Layout>
	)
}