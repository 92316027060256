import { useEffect } from "react";
import { useDependencies} from './useDependencies'

export const usePageTitle = (title: string): void => {
    const { analytics } = useDependencies();

    useEffect(() => {
        document.title = `${title?.length > 0 ? title + ' - ' : ''}Swimpion`;
        
        const titleToLog = title?.substring(0, title?.indexOf('-'));

        analytics.log(`${titleToLog ? titleToLog.trim() : 'Home'}_PageViewed`);
    }, []);
}