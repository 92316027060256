import { IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
import { Wrapper } from './styles';

export const Stars = () => {
	return(
        <Wrapper>
            <IonIcon icon={star} style={{ fontSize: 18, color: '#0CA36C', margin: '0 1px' }} />
            <IonIcon icon={star} style={{ fontSize: 18, color: '#0CA36C', margin: '0 1px' }} />
            <IonIcon icon={star} style={{ fontSize: 18, color: '#0CA36C', margin: '0 1px' }} />
            <IonIcon icon={star} style={{ fontSize: 18, color: '#0CA36C', margin: '0 1px' }} />
            <IonIcon icon={star} style={{ fontSize: 18, color: '#0CA36C', margin: '0 1px' }} />
        </Wrapper>					
	)
}