import { Wrapper, Spacer, Content } from '../../styles';
import { ContentBlockProps } from './types';

export const ContentBlock = (props: ContentBlockProps) => {
    const { children } = props;

    return (
        <Wrapper>
            <Spacer>
                <Content>
                    { children }
                </Content>
            </Spacer>
        </Wrapper>
    )
}