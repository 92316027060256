import styled from 'styled-components';
import { DescriptionStyleProps } from './types';


export const Container = styled.div<DescriptionStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    margin-top: 100px;
    height: calc(100vh - 400px);
    ${props => props.isNext ? 'margin-top: 0px;' : 'background-color: #fff;'}

    @media only screen and (max-width: 960px) {
        height: auto;
    }
`;

export const Inner = styled.div<DescriptionStyleProps>`
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    margin: 0 40px;

    @media only screen and (max-width: 960px) {
        flex-direction: column;
        height: auto;
        padding: 0 5%;
        ${props => props.isNext && 'flex-direction: column-reverse;'}
    }

    @media only screen and (max-width: 480px) {
        margin: 0 20px;
    }
`;

export const Col = styled.div<DescriptionStyleProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

export const ColLeft = styled.div<DescriptionStyleProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-left: 100px;

    @media only screen and (max-width: 1200px) {
        padding-left: 50px;
    }

    @media only screen and (max-width: 960px) {
        padding-left: 0px;
    }
`;

export const ColRight = styled.div<DescriptionStyleProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding-right: 100px;

    @media only screen and (max-width: 1200px) {
        padding-right: 50px;
    }

    @media only screen and (max-width: 960px) {
        padding-right: 0px;
    }
`;

export const Title = styled.h2`
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 60px;
    margin: 0;
    padding: 0 0 10px 0;

    @media only screen and (max-width: 1200px) {
        font-size: 54px;
        line-height: 50px;
    }

    @media only screen and (max-width: 360px) {
        font-size: 44px;
        line-height: 40px;
    }
`;

export const Small = styled.span`
    font-size: 34px;

    @media only screen and (max-width: 1200px) {
        font-size: 24px;
    }
`;

export const Content = styled.p`
    font-family: "Karla", sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0px;

    @media only screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 960px) {
        font-size: 18px;
    }
`;

export const Img = styled.img`
    margin: 0;
    border-radius: 20px;
    filter: grayscale(1);

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    @media only screen and (max-width: 960px) {
        margin: 40px auto;
    }
`;