export const AppConfig = {
    firebase: {
        apiKey: "AIzaSyCyMceOIsU_tWSiNkACqkJkEcT5vf5NdFs",
        authDomain: "gama-iswim.firebaseapp.com",
        databaseURL: "https://gama-iswim.firebaseio.com",
        projectId: "gama-iswim",
        storageBucket: "gama-iswim.appspot.com",
        messagingSenderId: "898739040430",
        appId: "1:898739040430:web:75933514ea9bb2813fbd3f",
        measurementId: "G-M6MNLDGK7W"
    },
    amplitude: {
        apiKey: 'dd15dae3268564df076aab1c20270ee6'
    }
}