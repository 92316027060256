import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FooterStyleProps } from './types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0px;

    @media only screen and (max-width: 760px) {
        flex-direction: column;
        align-items: center;
    }
`;


export const Nav = styled.ul<FooterStyleProps>`
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    ${props => props.right && 'margin-left: auto;'}

    @media only screen and (max-width: 760px) {
        margin: auto;

        :first-child {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }

    @media only screen and (max-width: 640px) {
        margin: auto;

        :first-child {
            flex-direction: column;
            text-align: center;

            li {
                padding-bottom: 16px;
            }
        }
    }
`;

export const Item = styled.li`
    margin-left: 10px;

    :first-child {
        margin-left: 0;
    }

    @media only screen and (max-width: 420px) {
        :first-child {
            margin-left: 10px;
        }
    }
`;

export const Link = styled(NavLink)`
    color: #b2b2b2;
    font-family: "Karla";
    font-weight: 300;
    text-decoration: none;
    padding: 8px 10px;
    font-size: 16px;

    :hover {
        color: #fff;
    }

    :first-child {
        padding-left: 0;
        margin-left: 0;
    }
`;

export const A = styled.a`
    color: #b2b2b2;
    font-family: "Karla";
    font-weight: 300;
    text-decoration: none;
    padding: 8px 10px;
    font-size: 16px;

    :hover {
        color: #fff;
    }

    :first-child {
        padding-left: 0;
        margin-left: 0;
    }
`;

export const SocialLink = styled.a`
    display: inline-block;
    padding: 10px;
`;

export const SocialIcon = styled.img`
    width: 32px;
    fill: #fff;
`;
